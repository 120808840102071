<template>
    <div class="dF fC f1 px-4 pb-4 hide-scrollbar" style="background:var(--light-bg-gray); overflow-y: auto;" v-if="projects.length">
        <NewSiteDialog :start="newSiteBoolean" @close="newSiteBoolean = false"/>
        <a-modal v-model="dialog.show" :title="dialog.title" @ok="handleDialogOK" @cancel="closeDialog">
            <a-form-model :model="dialog">
                <a-form-model-item :label="`Enter project name`">
                    <a-input v-model="dialog.value" :placeholder="dialog.placeholder" />
                </a-form-model-item>
            </a-form-model>

        </a-modal>


        <div class="f1 rounded">
            <div class="project-wrapper">
                <ProjectCard v-for="prj in queriedProjects" :key="prj.id" :project="prj" @edit="editProject(prj)" @delete="deletePRJ(prj)" @rename="renamePRJ(prj)" @preview="previewPRJ(prj)" @duplicate="duplicatePRJ(prj)" />
            </div>
        </div>

    </div>
    <div class="dF fC f1 px-4 pb-4 " style="background:var(--light-bg-gray)" v-else>
        <NewSiteDialog :start="newSiteBoolean" @close="newSiteBoolean = false"/>
        <EmptyApp :img="require('@/assets/empty.svg')" :text="`You don't have any projects`"  />
    </div>
</template>

<script>
    import {formatDate} from 'bh-mod'
    import NewSiteDialog from '@/components/index/NewSiteDialog'
    import ProjectCard from '@/components/index/ProjectCard'
    import BHLoading from 'bh-mod/components/common/Loading'
    import EmptyApp from 'bh-mod/components/common/EmptyApp'
    import axios from 'axios'

    export default {
        components:{
            BHLoading,EmptyApp,NewSiteDialog,ProjectCard,
        },
        data() {
            return {
                dialog:{
                    value:'',
                    show:false,
                    placeholder:'',
                    type:'',
                    id:'',
                },
                newSiteBoolean:false,
                addSiteBoolean:false,
                timeNow:Date.now(),
                mailOpen:'',
                filter:'all',
                baseImage:'',
                loadModal:false,
                taskOpen:'',
                appointmentOpen:'',
                searchMode:false,
                activeTab:'1',

            }
        },
        watch:{
            searchTimeStamp(val){
                this.$store.dispatch('DO_SEARCH')
            },
            currentPage(){
                this.$store.dispatch('DO_SEARCH')
            },
        },
        computed:{
            queriedProjects(){
                return this.projects.filter(prj => prj.published === this.$store.state.appData.filterPublished)
            },
            projects(){
                return Object.values(this.$store.state.appData.projects)
            },

            instance(){
                return this.$store.state.instance
            },
            loadingPage:{
                get(){
                    return this.$store.state.appData.loadingPage
                },
                set(val){
                    this.$store.commit('LOAD_PAGE',val)
                }
            }
        },
        methods:{
            async previewPRJ(prj){
                this.$store.commit('LOAD',true)
                try{
					let {data:project} = await this.$api.get(`/projects/:instance/${prj.id}`)
					let indexid = ''
                    for (const key in project.pages) {
                        if (prj.pages.hasOwnProperty(key)) {
                            const page = project.pages[key];
                            let {data:saved} = await axios.post(`${this.$store.state.appData.siteURL}/savetmp`, {pageReq: page.slug,project: prj})
                            if (page.slug === 'index') indexid = page.id

                        }
                    }

                    if (indexid) window.open(`${this.$store.state.appData.siteURL}/tmp/${indexid}?updated=${Date.now()}`)
                    else this.$message.error('Could not find the index page to preview')

                    this.$store.commit('LOAD',false)


                }
                catch(err){
                    console.error('there was a problem trying to preview\n',err)
                    this.$store.commit('LOAD',false)
                    this.$message.error('There was a problem while previewing your touchscreen. Please try again.')
                }


            },
            confirmDeleteProject(id){
                this.$api.delete(`/projects/:instance/${id}`).then( () => {
                    this.$store.commit('SET_PROP', {where:['projects',id],del:true})
                }).catch( () => {
                    this.$message.error('There was a problem deleting. Please try again')
                })
            },
            deletePRJ(prj){
                let self = this
                this.$confirm({
                    title: 'Delete Project?',
                    content: h => <div>Are you sure want to delete this project? <br/><strong>This cannot be undone!</strong></div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk(){
                        self.confirmDeleteProject(prj.id)
                    },
                    onCancel(){
                        console.log('Cancel')
                    }
                })
            },
            closeDialog(){
                this.dialog = {
                    value:'',
                    show:false,
                    placeholder:'',
                    type:'',
                    id:'',
                }
            },
            handleDialogOK(){
                if (this.dialog && this.dialog.cb){
                    this.dialog.cb()
                }
            },
            renameConfirm(){
                let name = this.dialog.value
                this.$api.put(`/projects/:instance/${this.dialog.id}`, {name}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
                this.$store.commit('SET_PROP', {where:['projects',this.dialog.id,'name'],what:name})
                this.closeDialog()
            },
            renamePRJ(prj){
				if(this.$p <= 10 && prj.published) {
					this.$message.error('You do not have required permission to perform this action.')
					return
				}

                this.dialog.value=prj.name
                this.dialog.placeholder=prj.name
                this.dialog.show = true
                this.dialog.title = `Rename Project`
                this.dialog.type = 'rename'
                this.dialog.id = prj.id
                this.dialog.cb = this.renameConfirm
            },
            async confirmDuplicate(){

                this.$store.commit('LOAD',true)
                let {data:project} = await this.$api.get(`/projects/:instance/${this.dialog.id}`).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})

                project.name = this.dialog.value
                delete project.id
                delete project._id

                if (project.headers) project.headers = project.headers.map( ({id,_id,...obj}) => obj)
                if (project.footers) project.footers = project.footers.map( ({id,_id,...obj}) => obj)

                for (const key in project.pages) {
                    if (project.pages.hasOwnProperty(key)) {
                        const page = project.pages[key];

                        delete page.id
                        delete page._id

                        if (page.header) delete page.header.id,delete page.header._id
                        if (page.footer) delete page.footer.id,delete page.footer._id
                        if (page.sections) page.sections = page.sections.map( ({id,_id,...obj}) => obj)
                    }
                }

                this.$api.post('projects/:instance', project)
                    .then( ({data:saved}) => {
                        this.$router.push(`/edit/${saved.id}`)
                        this.$message.success('Duplicated project: ', this.dialog.value)
                    }).catch(err => {
                        this.$message.error('Error while duplicating project: ', this.dialog.value)
                    }).finally( () => {
                        this.closeDialog()
                        this.$store.commit('LOAD',false)
                    })
            },
            duplicatePRJ(prj){
                this.dialog.value = prj.name + ' COPY'
                this.dialog.placeholder=`Enter Project name...`
                this.dialog.show = true
                this.dialog.id = prj.id
                this.dialog.title = `Duplicate Project`
                this.dialog.type = 'duplicate'
                this.dialog.cb = this.confirmDuplicate

            },
            editProject(project){
				if(this.$p <= 10 && project.published) {
					this.$message.error('You do not have required permission to perform this action.')
					return
				}
                this.$router.push(`/edit/${project.id}`)
            },
            formatDate,
            openTemplate(template){
                this.$store.commit('SHOW_EDIT_TEMPLATE',template)
            },
            removeSearch({srch,searchKey}){
                this.$store.commit('REMOVE_SEARCH_PARAM',{srch,searchKey})
            },
        },
        mounted(){
            this.newSiteBoolean = false
        },
        created() {
            this.$store.commit('PAGE_BUTTONS', [
                {
                    label:'CREATE TOUCHSCREEN',
                    ant:'plus',
                    callback: () => this.newSiteBoolean = true
                }
            ])
        }

    }
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>

    .project-wrapper{

        @media screen and (max-width:768px){
            .project-card{
                &+ .project-card{
                    margin-top:20px;
                }
            }
        }
        @media screen and (min-width:768px){
            display:grid;
            grid-template-columns: repeat(1, minmax(100px,1fr));
            grid-template-rows: repeat(auto,auto-fill);
            gap:2ch;
            grid-template-columns: repeat(2, minmax(100px,1fr));
            gap:2ch;
        }
        @media screen and (min-width:1167px){
            grid-template-columns: repeat(3, minmax(100px,1fr));
            gap:2ch;
        }
        @media screen and (min-width:1300px){
            grid-template-columns: repeat(3, minmax(100px,1fr));
            gap:2ch;
        }
    }
    .contacts-page{
        @media screen and (min-width:567px){
            max-width: calc(100% - 35px);
        }
    }
    .aB{
        align-items: baseline;
    }

    .row-mb{
        [class^=ant-col]{
            margin-bottom:20px;
        }
    }
    .more-option-icon{
        width: 35px;
        height: 35px;
        border-radius: 35px;
        text-align: center;
        background-color: transparent;
    }
    .more-option-icon:hover{
		color: var(--orange) !important;
        background-color: var(--light-gray);
	}
    .popoverContent{
        height: 35px;
        width: 160px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
    .email-quick-preview{
        width:100%;
        max-width:700px;
        min-height:500px;
        max-height:calc(100vh - 200px);
    }



</style>

<style lang="scss">
    .broadcast-icon{
        fill: #9ea0a5;
    }

    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
    .tableStyle .ant-table-thead > tr > th {
        background-color: white;
    }
    .tableStyle .ant-table-pagination.ant-pagination{
        margin: 16px 16px;
    }
</style>
