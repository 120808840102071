<template>
    <div>
        <!-- <h4 class=mb-3>Fonts</h4> -->
        <div class="colr-grid">

            <div style="gap:2ch;">
                <div class="text-md mb-2">Primary heading</div>
                <div v-if="$store.state.appData.fontList.length">

                    <font-picker api-key="AIzaSyC5XCJYbRRAbcAZLdIp7OAY232lc96HiS8" :options="{name:'primary', variants:['regular', 'italic', '700', '700italic']}" :active-font="site.fonts.primary" @change="fontSelected($event,'primary')"></font-picker>
                    <div class="dF aC text-secondary mt-1" style="gap:1ch;">
                        Bold <a-switch size="small" v-model="bold.primary" />
                        &nbsp; Italic <a-switch size="small"  v-model="italic.primary" />
                    </div>
                    <!-- apiKey (required): Google API key (can be generated here)
                    activeFont: Font that should be selected in the font picker and applied to the text (default: 'Open Sans'). Must be stored in component state, and be updated using an onChange listener
                    options: Object with additional (optional) parameters:
                        name: If you have multiple font pickers on your site, you need to give them unique names (which may only consist of letters and digits). These names must also be appended to the font picker's ID and the .apply-font class name; e.g. if { name: 'main' }, use #font-picker-main and .apply-font-main
                        families: If only specific fonts shall appear in the list, specify their names in an array (default: all font families)
                        categories: Array of font categories – possible values: 'sans-serif', 'serif', 'display', handwriting', 'monospace' (default: all categories)
                        variants: Array of variants which the fonts must include and which will be downloaded; the first variant in the array will become the default variant (and will be used in the font picker and the .apply-font class); e.g. ['regular', 'italic', '700', '700italic'] (default: ['regular'])
                        limit: Maximum number of fonts to be displayed in the list (the least popular fonts will be omitted; default: 100)
                        sort: Sorting attribute for the font list – possible values: 'alphabetical' (default), 'popularity'
                    onChange: Function which is executed whenever the user changes the active font and its stylesheet finishes downloading -->
                </div>
                <div v-else>
                    <a-icon type="loading" size="large" />
                </div>
            </div>

            <div class="aC" style="gap:2ch;">
                <div class="text-md mb-2">Secondary heading</div>
                <div v-if="$store.state.appData.fontList.length">
                    <font-picker api-key="AIzaSyC5XCJYbRRAbcAZLdIp7OAY232lc96HiS8" :options="{name:'secondary', variants:['regular', 'italic', '700', '700italic']}" :active-font="site.fonts.secondary" @change="fontSelected($event,'secondary')"></font-picker>
                    <div class="dF aC text-secondary mt-1" style="gap:1ch;">
                        Bold <a-switch size="small" v-model="bold.secondary" />
                        &nbsp; Italic <a-switch size="small"  v-model="italic.secondary" />
                    </div>
                </div>
            </div>

            <div class="aC" style="gap:2ch;">
                <div class="text-md mb-2">Body Text</div>
                <div v-if="$store.state.appData.fontList.length">
                    <font-picker api-key="AIzaSyC5XCJYbRRAbcAZLdIp7OAY232lc96HiS8" :options="{name:'body', variants:['regular', 'italic', '700', '700italic']}" :active-font="site.fonts.body" @change="fontSelected($event,'body')"></font-picker>
                    <div class="dF aC text-secondary mt-1" style="gap:1ch;">
                        Bold <a-switch size="small" v-model="bold.body" />
                        &nbsp; Italic <a-switch size="small"  v-model="italic.body" />
                    </div>
                </div>
            </div>

            <div class="aC" style="gap:2ch;">
                <div class="text-md mb-2">Accent Text</div>
                <div v-if="$store.state.appData.fontList.length">
                    <font-picker api-key="AIzaSyC5XCJYbRRAbcAZLdIp7OAY232lc96HiS8" :options="{name:'accent', variants:['regular', 'italic', '700', '700italic']}" :active-font="site.fonts.accent" @change="fontSelected($event,'accent')"></font-picker>
                    <div class="dF aC text-secondary mt-1" style="gap:1ch;">
                        Bold <a-switch size="small" v-model="bold.accent" />
                        &nbsp; Italic <a-switch size="small"  v-model="italic.accent" />
                    </div>
                </div>
            </div>



        </div>
        <div class="dF jSB title-second-color-picker aC" style="salign-items:baseline">
            <h4 class="mb-0">Font Preview</h4>
            <!-- <a href="#" @click.prevent="generateFont" class="hover:text-primary text-sm" style="text-decoration:underline">Randomize Suggestion</a> -->
        </div>

        <div class="typo-preview">
            <h1 class="apply-font-primary" :style="getStyle('primary')" >Primary heading</h1>
            <h2 class="apply-font-secondary" :style="getStyle('secondary')">Secondary heading</h2>
            <p class="apply-font-body" :style="getStyle('body')">This is a sample of a body text typography. Please use this as a visualization guide to see how you like this font and change it if you have to.</p>

            <a-divider />
            <svg viewBox="0 0 123.961 123.961" style="enable-background:new 0 0 123.961 123.961;width:20px;" xml:space="preserve" class="mr-3">
                <path d="M49.8,29.032c3.1-1.3,4.4-5,3-8l-4.9-10.3c-1.4-2.899-4.8-4.2-7.8-2.899c-8.5,3.6-15.8,8.3-21.6,14
                    C11.4,28.532,6.6,36.232,4,44.732c-2.6,8.601-4,20.3-4,35.2v30.7c0,3.3,2.7,6,6,6h39.3c3.3,0,6-2.7,6-6v-39.3c0-3.301-2.7-6-6-6
                    H26.5c0.2-10.101,2.6-18.2,7-24.301C37.1,36.133,42.5,32.133,49.8,29.032z"/>
                <path d="M120.4,29.032c3.1-1.3,4.399-5,3-8l-4.9-10.199c-1.4-2.9-4.8-4.2-7.8-2.9c-8.4,3.6-15.601,8.3-21.5,13.9
                    c-7.101,6.8-12,14.5-14.601,23c-2.6,8.399-3.899,20.1-3.899,35.1v30.7c0,3.3,2.7,6,6,6H116c3.3,0,6-2.7,6-6v-39.3
                    c0-3.301-2.7-6-6-6H97.1c0.2-10.101,2.601-18.2,7-24.301C107.7,36.133,113.1,32.133,120.4,29.032z"/>
            </svg>


            <span class="apply-font-accent" :style="getStyle('accent')">Accent fonts will stylize quotes and stuff.</span>
        </div>


        <portal to="destination" class="hello">
            <a-button size="large" class="back-button mt-3" @click="next">Create Touchscreen</a-button>
        </portal>

    </div>
</template>

<script>

    import {getAppIcon,validateEmail,setProp} from 'bh-mod'
    import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
    import FontPicker from '@/components/common/font-selector';
    import axios from 'axios'


    var colorChoices = require('nice-color-palettes');

    export default {
        props:{
            value:Number
        },
        components:{
            ImageBoxSelector,FontPicker,
        },
        data:() => ({
            newTime:Date.now(),
            bold:{
                primary:true,
                secondary:false,
                body:false,
                accent:false
            },
            italic:{
                primary:false,
                secondary:true,
                body:false,
                accent:true
            },
            site:{
                fonts:{
                    primary:'Bitter',
                    secondary:'Open Sans',
                    body:'Open Sans',
                    accent:'Balsamiq Sans'
                }
            },
        }),
        computed:{
            fontList(){
                let fonts = this.$store.state.appData.fontList
                let obj = {}
                fonts.forEach(item => {

                    let boldWeights = []

                    let hasBold = false
                    let boldVariant = 0

                    let italicWeights = []
                    let hasItalics = false
                    let italicVariant = 0

                    let boldItalicWeights = []
                    let hasBoldItalics = false
                    let boldItalicVariant = 0



                    item.weights = item.variants.map( wght => {

                        if (wght === 'regular') return '400'
                        else if (wght === 'italic') return '400i'

                        if (wght.indexOf('italic') > 0) return wght.replace('italic','i')

                    })
                    item.weights.forEach( weight => {

                        let w = parseInt(weight)
                        if (isNaN(w)) return

                        if (weight.includes('i')){
                            hasItalics = true
                            italicWeights.push(w)
                            if (w > 250 && w < 600){
                                hasItalics = true
                                if (w === 400){

                                }

                            } else if (w > 600) {

                            }

                        } else if (w > 650) {

                            if (w == 700) {

                                hasBold = true
                                boldVariant = 700

                            } else if (w == 800) {

                                hasBold = true
                                boldVariant = 800

                            } else if (w == 900) {

                                hasBold = true
                                boldVariant = 900

                            }

                        }

                    })


                    item.category = item.category === 'handwriting' ? 'cursive' : item.category
                    item.hasBold = hasBold
                    item.boldVariant = boldVariant
                    obj[item.family] = item

                })
                return obj
            },
            fontObj(){
                let {primary,secondary,body,accent} = this.site.fonts

                let chosenPrimary = this.fontList[primary]
                let chosenSecondary = this.fontList[secondary]
                let chosenBody = this.fontList[body]
                let chosenAccent = this.fontList[accent]

                let makeFontObj = (f,cat = 'sans-serif',t) => {
                    f = f && f.trim()
                    let bold = this.bold[t]
                    let italic = this.italic[t]
                    let weights = ['400']

                    if (bold && italic) weights = ['700i']
                    else if (bold) weights = ['700']
                    else if (italic) weights = ['400i']

                    return {
                        name:f,
                        google:f && f.replace(/ /g, '+'),
                        fontText:`'${f}', ${cat}`,
                        bold,
                        italic,
                        weights
                    }
                }

                primary = makeFontObj(primary, chosenPrimary.category,'primary')
                secondary = makeFontObj(secondary, chosenSecondary.category,'secondary')
                body = makeFontObj(body, chosenBody.category,'body')
                accent = makeFontObj(accent, chosenAccent.category,'accent')

                return {
                    primary,
                    secondary,
                    body,
                    accent
                }
            },
            instance(){
                return this.$store.state.instance
            },
        },
        methods:{
            getStyle(t){

                if (!t) return ''
                let style = ''

                if (this.bold[t]) style += 'font-weight:700;'
                if (this.italic[t]) style+= 'font-style:italic'

                return style

            },
            fontSelected(e,f){

                this.site.fonts[f] = e.family

            },
            generateFont(){

                axios.get('https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyC5XCJYbRRAbcAZLdIp7OAY232lc96HiS8').then( ({data}) => {
                    this.$store.commit('SET_PROP', {where:['fontList'],what:data.items})
                })

            },
            next(){

                return this.$emit('done', {
                    fonts:this.fontObj
                })

            },


        },
        created(){
            this.generateFont()

        }
    }
</script>

<style lang="scss" >
    .typo-preview{
        padding: 20px;
        border: 1px solid #ccc;
    }
    .colr-grid{
        display:grid;
        grid-template-columns: repeat(1, 1fr);
        gap:1.5ch;
        > div {
            max-width: 300px;
        }
        @media screen and (min-width:1200px){
            gap:3.5ch;
            grid-template-columns: repeat(2, 1fr);
        }
    }
    .title-second-color-picker{
        margin-top:30px;
        margin-bottom:10px;
        @media screen and (min-width:1200px){
            margin-top:70px;
        }
    }
    .scheme-choice{
        display:flex;
        cursor:pointer;
        padding:5px;
        border:1px solid transparent;
        &.selected-color-suggestion{
            border:1px solid var(--secondary);
        }
        span{
            height:15px;
            flex:1;
            background:var(--color-here);
        }
    }

</style>
