<template>
  <div class="project-card cursor-pointer" @click="$emit('edit')">
      <div class="img" :style="`background-image:url(${image})`" />
      <div class="project-info-bottom dF p-2 jSB bg-white aC">
            <div>
                <span>{{project.name}}</span>
                <small class="block">Device: <span class="text-danger">Not connected</span></small>
            </div>
        <MoreActions :items="actions" @action="actioned" />
      </div>
  </div>
</template>

<script>
import MoreActions from 'bh-mod/components/common/MoreActions'
export default {
    components:{MoreActions},
    props:['project'],
    data:() => ({
    }),
    methods:{
        actioned(e){
            this.$emit(e.key)
        }
    },
    computed:{
        image(){
            if (!this.project.image || !this.project.image.trim()) return 'https://bildhive.nyc3.digitaloceanspaces.com/assets/noimageavailable.png'
            return this.project.image
        },

		actions() {
			const list = [
				// {
				// 	label:'Preview',
				// 	value:'preview',
				// 	icon:'fe-eye',
				// },
				{
					label:'Rename',
					value:'rename',
					icon:'fe-type',
				},
				{
					label:'Edit',
					value:'edit',
					icon:'fe-edit',
				},
				{
					label:'Duplicate',
					value:'duplicate',
					icon:'fe-copy',
				}
			]

			if(this.$p >=40) {
				list.push({
					label:'Delete',
					value:'delete',
					icon:'fe-trash',
            	})
			}

			return list;
		}
    },
    created(){

    }
}
</script>

<style lang="scss" scoped>
    .project-card{
        box-shadow:0 3px 5px rgba(100,100,100,0.03);
        &:hover{
            box-shadow:0 3px 5px rgba(100,100,100,0.1);
        }
       .img{
           border-bottom:1px solid #dedede;
           height:0;
           width:100%;
           padding-top:57%;
           background-size:cover;
           position: relative;
       }
    }
</style>
