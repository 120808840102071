<template>
    <div>
        <p></p>
        <!-- <a-form-model ref="siteForm" :model="site" :rules="rules">
                <a-form-model-item label="Dark Logo" prop="darkLogo" class="empty mt-4">
                    <ImageBoxSelector v-model="site.darkLogo"/>
                </a-form-model-item>

                <a-form-model-item label="Light Logo" prop="lightLogo" class="empty mt-4" data-hi>
                    <ImageBoxSelector v-model="site.lightLogo"/>
                </a-form-model-item>
            
        </a-form-model> -->
        <div v-for="(set,setI) in setting" :key="set+setI">
            <div v-if="set.type == 'header'">
                <h2 class="my-4">{{set.label}}</h2>
                <template v-for="(child,childI) in set.children">
                    <a-form-item :label="child.label" :key="child+childI">
                        <SectionEditItem :data="data" :setting="child"/>
                    </a-form-item>
                </template>
            </div>
        </div>

        <div>
            
        </div>
        
        <portal to="destination">
            <a-button size="large" class="back-button" @click="next" >Next</a-button>
        </portal>
       
    </div>
</template>

<script>

import SectionEditItem from '@/components/site/SectionEditItem'

export default {
    props:['data','setting','value'],
    components:{
        SectionEditItem
    },
    data:() => ({
        site:{
            title:'',
            darkLogo:'',
            lightLogo:''
        },
        currentStep:0,

        rules: {
            title: [
                { required: true, message: `Please input your site's title`, trigger: 'blur' }
            ],
            darkLogo: [
                { required: true, message: 'Please provide your dark logo' }
            ],
            lightLogo: [
                { required: true, message: 'Please provide your light logo' }
            ],
        },
        onBoarding:false,
    }),
    watch:{
        currentStep(val){
            return this.$emit('input',val)
        },
    },
    computed:{
        instance(){
            return this.$store.state.instance
        },
        theApp(){
            return this.$store.state.theApp
        },

    },
    methods:{
        skip(){
            return this.$emit('next')
        },
        next(){
            console.log('validddd', this.data)
            // return this.$emit('done', this.site)
            return this.$emit('done', this.data)
        },

    }
}
</script>

<style lang="scss">
    .ant-form-item.empty{
        span.ant-form-item-children{

        }
    }
</style>