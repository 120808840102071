<template>
    <div>

        <PreviewTemplate :template="templateToPreview" @close="templateToPreview = ''" />

        <Popup

            v-if="optionStep"
            @close="step = 2; optionStep = 0;"
            introImage="https://bildhive.nyc3.digitaloceanspaces.com/apps/contracts/screens.png"
            color="purple"
            :step="optionSteps[optionStep - 1]"
            v-model="optionStep"
            title="Headline goes here" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac erat gravida, tempor magna quis, lacinia ex. Pellentesque ac ex aliquam dui feugiat consequat. Morbi id dolor enim. Mauris dictum ligula">

            <template v-slot:right v-if="optionStep">
                <component :is="'OptionStep' + optionStep" :data="newProject.data" :setting="newProject.settings" @done="nextOptions" />
            </template>

        </Popup>


        <div class="choose_template_modal" v-if="step === 2">
            <!-- <div class="choose_template_modal-mask"></div> -->

            <div class="choose_template_modal-wrapper">

                <div class="choose_template_modal-body">
                    <div class="choose_template_modal-header dF jC">
                        <h3>Pick your theme</h3>
                    </div>

                    <template v-if="templates && templates.length">

                        <div class="template-list-wrapper">
                            <template v-for="item in templates">
								<template v-if="item.productType && item.productType == instance.productType">
                                	<TemplateCard :item="item" :key="item.id" :selected="item.name === newProject.template" @select="newProject.template = item.name" @preview="previewTemplate(item)"  />
								</template>
							</template>
                        </div>

                    </template>
                    <template v-else>
                        <div class="dF jC aC w-full h-full">
                            <a-icon type="loading" />
                            <div>Loading</div>
                        </div>
                    </template>
                </div>
                <div class="choose_template_modal-footer dF jE py-4 px-8">
                    <a-button type=secondary @click="$emit('close')">CANCEL</a-button>
                    <a-button type=primary class=ml-2 @click="showOptions">CONTINUE</a-button>
                </div>
            </div>
        </div>

        <a-modal title="Create new Touchscreen" :visible="step === 1" @cancel="$emit('close')" @ok="showSelectTemplate" >
            <a-form-model :rules="rules" :model="newProject" ref="newSite">
                <a-form-model-item label="Touchscreen project Name" prop="name">
                    <a-input v-model="newProject.name" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>

    </div>
</template>

<script>
import axios from 'axios'
import TemplateCard from '@/components/site/TemplateCard'
import OptionStep1 from '@/components/index/OptionStep1'
import OptionStep2 from '@/components/index/OptionStep2'
import OptionStep3 from '@/components/index/OptionStep3'
import PreviewTemplate from '@/components/site/PreviewTemplate'
import Popup from '@/components/index/OptionPopup'
import {getCookie} from 'bh-mod'

export default {
    components:{
        TemplateCard,
        PreviewTemplate,
        Popup,
        OptionStep1,
        OptionStep2,
        OptionStep3

    },
    props:['start'],
    data:() => ({
        optionSteps:[
            {
                title:'Setting up your Touchscreen Common Settings',
                backButton:true
            },
            {
                title:'Define your colour palette',
                backButton:true
            },
            {
                title:'Select your typography',
                backButton:true
            },
        ],
        templateToPreview:'',
        templates:[],
        step:0,
        optionStep:0,

        rules:{
            name: [
                { min: 2, message: 'Name should be longer than 2 characters!', trigger: 'blur' },
                { required:true, message: 'Please enter a project name', trigger: 'blur' },
            ],
        },
        newProject:{
            template:'',
            name:'',
            settings:[],
            data:{},
            _global:{},

        }

    }),
    computed:{
        templatesTransformed(){
            let res = this.templates
            return res
        },
		instance(){
			return this.$store.state.instance
		}
    },
    watch:{
        optionStep(val){
            if (val === 0){

            } else {

            }
        },
        step:{
            immediate:true,
            async handler(val){
                if (val === 2){
                    if (!this.templates.length){
                        // let {data} = this.$api.get(`/projects/:instance/?type=web&default=true`)
                        axios.post(`${this.$store.state.appData.siteURL}/list/touch-templates`).then( ({data}) => {
                            this.templates = Object.values(data).filter(x => x.pages && Object.keys(x.pages).length)
                        })
                    }
                }
            }
        },
        start(val){
            console.log('STARTINGIGNGIGNNGG', val)
            if (!val){
                this.newProject.type = 'landing'
                this.newProject.name = ''
                this.newProject.template = ''
                this.step = 0
            } else {
                this.step = 1
            }
        },

    },
    methods:{
        nextOptions(site){

            Object.entries(site).forEach( ([parentKey,parentValue]) => {
                console.log('parentKey', parentKey)
                console.log('parentValue', parentValue)
                if (typeof parentValue !== 'object') this.newProject.data[parentKey] =  parentValue
                else {

                    if (!this.newProject._global[parentKey]) this.newProject._global[parentKey] = {}

                    Object.entries(parentValue).forEach( ([childKey,childValue]) => {
                        this.newProject._global[parentKey][childKey] = childValue
                    })

                }


            })

            if (this.optionStep < 3) this.optionStep += 1
            else {

                this.$store.commit('LOAD', true)

                let __bhjt = getCookie('__bhjt')
                console.log('NEW PROJECT', this.newProject)

                axios.post(this.$store.state.appData.siteURL + '/create-touch/' + this.$store.state.instance.id, this.newProject)
                    .then( ({data}) => {
                        this.$store.commit('LOAD',false)
                        console.log('DATA COMING BACK FROM CREATE TOUCH', data)
                        let pages = data.pages
                        pages.forEach(x => {
                            if (x.name == 'page-siteplan' || x.name == 'page-features' || x.name == 'page-amenities' || x.name == 'page-models'){
                                x.sections[0].data.instance = this.$store.state.instance.id
                            }
                        })
                        data.pages = pages
                        this.$api.post('projects/:instance', data)
                            .then( ({data:saved}) => {
                                console.log('Touchscreen saved...', saved)
                                this.$store.commit('UPDATE_PROJECT', saved)
                                this.$router.push(`/edit/${saved.id}`)
                            }).catch(err => {
                                // console.error('Someting happend while Saving project')
                            })

                    }).catch( err => {
                        console.error('Someting happend while Creating project', err)
                        this.$store.commit('LOAD',false)
                    })

            }
        },
        previewTemplate(tmp){
            // this.templateToPreview = tmp.name
			console.log('TMP', tmp);
			let path = tmp.pagesFolder

			let pathArr = path.split('/')
			let lastPart = pathArr[pathArr.length - 1]
			console.log('LAST PART', lastPart)
            window.open(`https://preview.bildhive.dev/?t=${lastPart}`)
        },
        showSelectTemplate(){
            this.$refs.newSite.validate( async res => {
                console.log('RESSSSSS',res)

                if (res) this.step = 2
            })
        },
        showOptions(){
            let foundTemplate = this.templates.find(x => x.name === this.newProject.template)
            if (!foundTemplate) return this.$message.error('Please select a Template to continue')

            this.newProject.settings = foundTemplate.settings || []
            console.log('NEW PROJECT SETTINGS', this.newProject.settings)
            if (foundTemplate.settings && foundTemplate.settings.length){
                foundTemplate.settings.forEach(itm => {
                    if (itm.children && itm.children.length){
                        itm.children.forEach(childItem => {
                            if (childItem.hasOwnProperty('default')){
                                this.newProject.data[childItem.id] = childItem.default
                            }
                        })
                    } else if (itm.hasOwnProperty('default')){
                        this.newProject.data[itm.id] = itm.default
                    }
                })
            }
            this.step = -1
            this.optionStep = 1

            console.log('FOUND TEMPLATE', foundTemplate, this.newProject)

        },
        toNextStep(){

        }
    }

}
</script>

<style lang="scss" scoped>
    .template-list-wrapper{
        display:grid;
        grid-template-columns: repeat(4, minmax(100px,1fr) );
        gap:4ch;

    }
    .choose_template_modal{
        position: absolute;
        left:0;
        top:0;
        .choose_template_modal-wrapper{
            z-index:300;
            position: fixed;
            left:0;
            width:100vw;
            top:0;
            height:100vh;
            background:white;
            display:flex;
            flex-direction: column;
            min-height:0;
        }
        .choose_template_modal-body{
            flex:1;
            overflow: auto;
            min-height:0;
            padding:0 2.5rem;
        }
        .choose_template_modal-header{
            padding:2rem;
            padding-top:4rem;
        }
        .choose_template_modal-footer{
            padding:4rem 2.5rem;
        }
    }

</style>
